import { useEffect, useState } from "react";
import "../style.css" 
import Axios from "axios";

import React from "react";

export default function BlogMemCol () {

  const [blogs, setBlogs] = useState(null);
  const [blogarticles, setBlog] = useState([])
  function refreshState () {
    window.location.href()
  }
  
  useEffect(() => {
    Axios.get("https://bontech2.herokuapp.com/readblogs").then((response) => {
      console.log(response);
      setBlog(response.data, response.key)
  })}, [])

  return (
        
        <div className="mem--blog--col">
              {blogarticles.map((val, key) => {
                return <div className="blog--preview--front" key={key}>
                    <h3>{val.post_title}</h3>
                    <h4>Author: {val.post_author}</h4>
                    <h4>Summary: {val.post_abstract}</h4>
                    <h5>{val.post_fulltext}</h5>
                    </div>
            })}
        </div>
   )
}
