import { useParams } from "react-router-dom";
import {Route, Routes, useLocation} from "react-router-dom";
import { useEffect, useState } from "react";
import useFetch from "../useFetch";
import Axios from "axios";
import {LinkedinShareButton} from "react-share";
import {LinkedinIcon} from "react-share";
import BlogArticlesCol from "../components/blogsonecol"
import industry from '../components/images/industry.jpeg'

import React from "react";
import BlogArticlesdb from "./Blogdb";

const SingleBlog = () => {
    
    const {blogid} = useParams()
    const [blogarticles, setBlog] = useState([])
    console.log(blogarticles)
    const blogurl = useLocation();
    const pathurl = blogurl.pathname
    //console.log(`https://bøn.tech/#${pathurl}`)
    console.log(pathurl)
    const linkedurl = `https://xn--bn-lka.tech/#${pathurl}`
    console.log(linkedurl)

  
  useEffect(() => {
    Axios.get(`https://bontech2.herokuapp.com/readspecblog/${blogid}`).then((response) => {
      console.log(response);
      console.log(blogid)
      setBlog(response.data, response.key)
  })}, [])


    return(
        
      <div>
            
        <div className="body--text">
        <div className='body--text--item1'>
          <p>What We Do</p>
          <ul className="sidemenu">
            <li>Data/Privacy</li>
            <li>Cybersecurity</li>
            <li>Telecoms</li>
            <li>IP / Confidentiality</li>
          </ul>
          
        </div>
        
        <div className="blog--single--body">
                      
        {blogarticles.map((val, key) => {
              return <article className="blog--single--article" key={key}>
                    <h3>{val.post_title}</h3>
                    <div className="linkedin--share--blog">
                    <LinkedinShareButton
                    url={linkedurl}
                    summary={"new post"}
                    title={val.post_title}>
                    <LinkedinIcon size={49} round={true} />
                    </LinkedinShareButton> 
                    </div>
                    <h4>Author: {val.post_author}</h4>
                    <h4>Summary: {val.post_abstract}</h4>
                    <h4>Tags: {val.post_tags}</h4>
                    {/* <h5>{val.post_fulltext}</h5> */}
                    <div className="blog--preview--front" dangerouslySetInnerHTML={{ __html: val.post_fulltext2}} />
                    </article>
            })}
            </div>
           <div className="blog--single--body--allblogs"> 
              
           <BlogArticlesdb/>
           </div> 
        </div>
        </div>
      
    )

} 

export default SingleBlog