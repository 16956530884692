import React from "react"
import { useParams } from "react-router-dom";
import {Route, Routes, useLocation} from "react-router-dom";
import { useEffect, useState } from "react";
import Axios from "axios";



const ShowPrivacy = () => {
    
    const pageid = 3
    const [singlepage, setPage] = useState([])
    console.log(singlepage)
    const blogurl = useLocation()
    console.log(blogurl)

  
  useEffect(() => {
    Axios.get(`https://bontech2.herokuapp.com/readpage/${pageid}`).then((response) => {
      setPage(response.data, response.key)

  })}, [])

  return(
   
   <div className="blog--single--body">
    <div className="foto--row">
     {/*<img className="foto"
     src={profile}>
  </img>  */}
     </div>
    <div className="cookie--page">  
    <div className='body--text--item1'>
        <p>Areas of Practice</p>
      <ul className="sidemenu">
          <li>Data/Privacy</li>
          <li>Cybersecurity</li>
          <li>Telecoms</li>
          <li>IP / Confidentiality</li>
      </ul>
      </div>
      <div className="body--text--item2">
    {/*<h1>{pageid}</h1>  */}
          
      <article className="privacy--page">
        <h1>Cookie Policy</h1>
          <p>Our website uses cookies, in combination with pixels, local storage objects, and similar devices (collectively, "cookies" unless otherwise noted) to distinguish you from other users of the website.</p>
          <p>For further information about this policy, please contact us at privacy@bøn.io</p>
          <h2>What is a cookie?</h2>
          <p>A cookie is a small file of letters and numbers that we store on your browser or the hard drive of your computer. When you visit our site, strictly necessary cookies will be placed on your device. You can change the cookie settings that will be placed when you visit our website by changing the settings on your browser.</p>
          <h2>Key Concepts</h2>
          <p>First and third-party cookies: whether a cookie is 'first' or 'third' party refers to the domain placing the cookie. First-party cookies are those set by a website that is being visited by the user at the time (e.g. cookies placed by www.bøn.tech).</p>
          <p>Third-party cookies are cookies that are set by a domain other than that of the website being visited by the user. If a user visits a website and another entity sets a cookie through that website, this would be a third-party cookie.</p>
          <p>Persistent cookies: these cookies remain on a user's device for the period of time specified in the cookie. They are activated each time that the user visits the website that created that particular cookie.</p>
          <p>Session cookies: these cookies allow website operators to link the actions of a user during a browser session. A browser session starts when a user opens the browser window and finishes when they close the browser window. Session cookies are created temporarily. Once you close the browser, all session cookies are deleted.</p>
          <h2>How to delete and block our cookies</h2>
          <p>You can block cookies by activating the setting on your browser that allows you to refuse the setting of all or some cookies. However, if you use your browser settings to block all cookies (including strictly necessary cookies), you may not be able to access all or parts of our site.</p>
          <h2>Changing your cookie settings</h2>
          <p>Please note that internet browsers allow you to change your cookie settings. These settings are usually found in the 'options' or 'preferences' menu of your internet browser. In order to understand these settings, the following links may be helpful. Otherwise you should use the 'Help' option in your internet browser for more details.</p>
         
          <h2>Can I withdraw my consent?</h2>
          <p>If you wish to withdraw your consent at any time, you will need to delete your cookies using your internet browser settings.</p>
          <h2>Your consent</h2>
          <p>Strictly necessary cookies do not require your consent.</p>
          <p>For performance, functionality, targeting and social media cookies, we request your consent before placing them on your device. You can give your consent by continuing to use our website, or by clicking on the appropriate button on the banner displayed to you. If you do not wish to give consent or wish to withdraw your consent to any performance, functionality, targeting or social media cookies at any time, you will need to delete, and block or disable cookies via your browser settings; see below for more information on how to do this. Please note that disabling these cookies will affect the functionality of the website and may prevent you from being able to access certain features on the website.</p>
          <h2>What cookies do we use and why?</h2>
          <p>To find out about specific cookies we use on this site, please see below.</p>
          <p>The cookies used on our site are categorised as follows:</p>

          <p>'Strictly Necessary' cookies let you move around the website and use essential features like secure areas. Without these cookies, we cannot provide the requested services.</p>
          <p>We use these Strictly Necessary cookies to:</p>
          
          <ul>
            <li>Identify you as being logged in to the website and to authenticate you.</li>
            <li>Make sure you connect to the right service on the website when we make any changes to the way it works.</li>
            <li>For security purposes</li>
          </ul>

          <p>If you prevent these cookies, we can't guarantee how the website or the security on the website will perform during your visit.</p>
          <h2>Performance</h2>
          <p>'Performance' cookies collect information about how you use our website - e.g. which pages you visit and if you experience any errors. These cookies do not collect any information that could identify you and are only used to help us improve how our website works, understand what interests our users and measure how effective our content is.</p>
          <p>We use performance cookies to perform:</p>
          <ul>
            <li>Web Analytics: Provide anonymous statistics on how our website is used</li>
            <li>Error management: Help us improve the website by measuring any errors that occur.</li>
          </ul>

          <p>Some of our performance cookies are managed for us by third parties. However, we don't allow the third party to use the cookies for any purpose other than those listed above.</p>
          
          <h2>Functional</h2>
          <p>We use functional cookies to enable us to provide you with enhanced functionality and personalisation. These cookies may be set by third party providers whose services we have added to our pages.</p>
          <p>If you do not add these cookies then some of these services may not function properly.</p>

          <h2>Targeting</h2>
          <p>We give users the option to share our stories on social networks such as LinkedIn.</p>
          <h2>Social media</h2>
          <p>These cookies are set by a range of social media services that we have added to our website to enable you to share our content with your peers or networks. They are capable of tracking your browser across other sites and building up a profile of your interests. This may impact the content and messages you see on other websites you visit.</p>   

      </article>
     </div>

     <div className="cookie--table">
     <h2>Cookies Used</h2>
    <table>
  <tr>
    <th>Cookie Name</th>
    <th>Category</th>
    <th>Purpose</th>
    <th>Duration</th>
    <th>Third Party</th>
  </tr>
  <tr>
    <td>_gid</td>
    <td>Performance</td>
    <td>Analytics</td>
    <td>Daily</td>
    <td>Google Inc.</td>

  </tr>
  <tr>
    <td>_ga</td>
    <td>Performance</td>
    <td>Analytics</td>
    <td>Daily</td>
    <td>Google Inc.</td>
  </tr>
  <tr>
    <td>_ga_gtag_UAX...</td>
    <td>Performance</td>
    <td>Analytics</td>
    <td>Daily</td>
    <td>Google Inc.</td>
  </tr>
  <tr>
    <td>_dd_s...</td>
    <td>Strictly Necessary</td>
    <td>Security/Logging</td>
    <td>Daily</td>
    <td>N/A</td>
  </tr>
  <tr>
    <td>CookieConsent</td>
    <td>Strictly Necessary</td>
    <td>Security/Logging</td>
    <td>30 days</td>
    <td>N/A</td>
  </tr>
</table>
    
    </div>  
    </div>
   
   </div>
    
  )
  }
  export default ShowPrivacy