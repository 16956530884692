
import { useEffect, useState } from "react";
import "../style.css" 
import industry from "../components/images/industry.jpeg"
import { Link } from "react-router-dom";
import Axios from "axios";
import {LinkedinShareButton} from "react-share";
import {LinkedinIcon} from "react-share";
import React from "react";

export default function MembersDb () {

  const [works, setWorks] = useState(null);
  const [memberslist, setMembers] = useState([])
  
  useEffect(() => {
    Axios.get("https://bontech2.herokuapp.com/readmembers").then((response) => {
      console.log(response);
      setMembers(response.data, response.key)
  })}, [])


  return (
                
        <div className="body--text--work--grid">
              {memberslist.map((val, key) => {
                return (
              <div className="member--preview" key={key} onClick={() => {window.location.assign(`${val.bon_memlinkedin}`)}}>
                
                <LinkedinShareButton
                url=""
                summary=""
                title={val.bon_memlinkedin}>
                <LinkedinIcon size={29} round={true} />
                </LinkedinShareButton>
                
                <h3>{val.bon_membername}</h3>
                <h5>{val.bon_memexperience}</h5>
              </div>
            )}
           )
        }
        </div>
      )}