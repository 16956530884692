import '../App.css';
import React from 'react';
import '../index.css';
import "../style.css"
import {Route, Routes} from "react-router-dom";
import BlogArticlesdb from "./Blogdb"

export default function Blog () {
    const title = "https://www.bøn.tech";
    const summary = "hola";
    const source = "bøn";
    return(  
        <div>
                  
          <div className='body--text--blog'>
          <div className="body--text--blog--item2">
             <BlogArticlesdb/>
          </div>
          
      <div className='body--text--blog--item1'>
        <p>What We Do</p>
          <ul className="sidemenu">
            <li>Data/Privacy</li>
            <li>Cybersecurity</li>
            <li>Telecoms</li>
            <li>IP / Confidentiality</li>
          </ul>
      {/*  <p>Where</p>
      
      <ul className="sidemenloc">
        <li>Paris</li>
        <li>London</li>
        <li>Berlin</li>
      </ul>*/}
      </div>
      
      </div>
      <div>
          <Footerbon/>
        </div>
    </div>
    )
 }
 
 function Footerbon() {
  return(
    <div className='footer--main'>
   
    <p>{" "}</p>
    <h6>©2022 Pycat Ltd.</h6>
    </div>
  )

}