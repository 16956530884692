import '../App.css';
import React from 'react';
import '../index.css';
import "../style.css"
import {Route, Routes} from "react-router-dom";
import WorkDb from '../components/Workdb';
import Footerbon from '../components/footerbon';
import BlogArticlesCol from '../components/blogsonecol';



export default function AllMatters () {
    const title = "https://www.bøn.tech";
    const summary = "hola";
    const source = "bøn";
    return(  
        <div>
               
          <div className='work--body--grid'>
          <div className="work--body--item3">
             <WorkDb/>
          </div>

        <div className="work--body--item2">
         <BlogArticlesCol />
        </div>

      <div className='body--text--blog--item1'>
        <p>What We Do</p>
          <ul className="sidemenu">
            <li>Data/Privacy</li>
            <li>Cybersecurity</li>
            <li>Telecoms</li>
            <li>IP / Confidentiality</li>
          </ul>
      
      </div>
        
      </div>
      <div>
          <Footerbon/>
        </div>
    </div>
    )
 }
 