import React from 'react'
import ReactDOM from 'react-dom'
import '../index.css'
import bonlogo from "../components/images/bonlogo.png"
import data from "../components/data"


// the export default makes this function usable by the whole application


function Estados() {
  
  const result = React.useState("CAGADA")
  /*using array de-structuring */
  const [isImportant, funcion] = React.useState(0)
  console.log(result)

  function handleMouseOver() {
    console.log("pedo", "cagon")
    funcion(function(isImportant){return isImportant + 29}) 
  }
/* the above funcion and function uses the old value of isImportant and adds 29 using a sub-function */
/* the function that comes with .useState is setState. In the above line funcion is a const name for setState*/

  function handleMouseLeave() {
    funcion(isImportant - 39)
  }

  return(
    <div className='content--form--2' onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      <h3>Que te comiste?</h3>
      <h2>{result[0]}</h2>
      <h2>{isImportant}</h2>
    </div>
)  
}

export default function Contact() {

/*this is a training React section for testing*/


/*the following is future contact or blog form for Bøn.tech*/


  let fotito

  const [things, setThings] = React.useState(["Pedo 1", "Pedo 2"])

  function handleClick() {
    const fotoArray = data
    const randomNumber = Math.floor(Math.random() * fotoArray.length)
    const newThingText = `Pedo ${things.length +1}`
    setThings(prevState => [...prevState, newThingText])
    console.log(randomNumber)
    console.log("I was clicked")
    fotito = fotoArray[randomNumber].foto
    console.log(fotito)
  }


  const thingsElements = things.map(thing => <p key={thing}>{thing}</p>)



  return(

    <div className= "content--form">
      <div className="content-form--boxes">

        <input type="text"
        className="form--input"
        placeholder="First Name"/>

        <input type="text"
        placeholder="Family Name"
        className="form--input" />
        
        <Estados />
        
        <button
          className="form--button"
          onClick={handleClick}
          /*onMouseOver={handleMouseOver}>*/
          >
          Submit Enquiry</button>
          {thingsElements}

        <img className ="fotito"
          src={fotito}
          alt ="Sorete"
          />
      </div>  
    </div>
   
  )
}


//console.log(Contact)

//Event Listner in javascript: .addEventListener("click", function() {here add the commands})
//
