export default [

{ id: 1,
  artdate: "01/14/2022",
  title: "Como cagar fuerte",
  price: 1355,
  tags:{
      pija: "yes",
      sorete: "no",
      caca: "yes",
    },
  emedia: "twitter",
  foto: "https://www.freeimages.com/photo/links-1177906",
},

{id: 2,
 artdate: "01/09/2022",
 title: "Chajá que rico",
 price: 122,
 tags:{
     pija: "yes",
     sorete: "yes",
     caca: "yes",
   },
 emedia: "linkedin",
 foto: "NLedesma.jpeg",
},

{id: 3,
 artdate: "01/09/2022",
 title: "Que lindo macho!",
 price: 2698,
 tags:{
     pija: "grande",
     sorete: "chico",
     caca: "sin",
   },
 emedia: "gaydar" ,
 foto: "nelly.jpeg",
},

]
