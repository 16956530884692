
import industry from '../components/images/industry.jpeg'
import ShowPage from "../components/showpage"
import WorkDb from '../components/Workdb'
import Footerbon from '../components/footerbon'
import React from 'react'

export default function OnePage () {
    return(
    <div>
      
     <div className='body--text--blog'>
     
      <div className="body--text--item2">

        <ShowPage/>
      </div>
      <div className='body--text--item1'>
        <p>What We Do</p>
      <ul className="sidemenu">
          <li>Data/Privacy</li>
          <li>Cybersecurity</li>
          <li>Telecoms</li>
          <li>IP / Confidentiality</li>
      </ul>
     {/*   <p>Where</p>
      <ul className="sidemenloc">
        <li>Paris</li>
        <li>London</li>
        <li>Berlin</li>
      </ul>*/}
     </div>

      <div className="body--text--item3">
         <WorkDb />
      </div>
  </div>

    <Footerbon/>

  </div>
    )
  }