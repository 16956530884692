import './App.css';
import React from 'react';
import './index.css';
import Headerbon from './components/header';
import "./style.css";
import {BrowserRouter as Router, Route, Routes, HashRouter} from "react-router-dom";
import Contact from "./pages/contact";
import Home from "./Home";
import Blog from "./pages/Blog";
import ShowBlogs from './pages/showarticles';
import SingleBlog from './pages/testgetblog';
import OnePage from './pages/singlepage';
import AllMatters from './pages/clientwork';
import SingleWork from './components/singlework';
import AllMembers from './pages/members';
import Privacy from './pages/privacy';


function App () {
  return(
    <HashRouter>
      <Headerbon />
      <Routes>
      <Route path="*" element={<Home/>} />
      <Route path="/blog/*" element={<Blog/>} />
      {/*}  <Route path="related" element={<Related />} />
      </Route>*/}
      <Route path="/contact" element={<Contact/>} />
      <Route path="/about/1" element={<OnePage/>} />
      <Route path="/blog/*" element={<Blog/>} />
      <Route path="/blogs/:blogid" element={<SingleBlog/>} />
      <Route path="/blogfolg/:blogid" element={<SingleBlog/>} />
      <Route path='/showblogs/' element={<ShowBlogs/>}/>
      <Route path='/work/*' element={<AllMatters/>}/>
      <Route path="/matters/:workid" element={<SingleWork/>} />
      <Route path="/members" element={<AllMembers/>}/>
      <Route path="/policy" element={<Privacy/>} />
               
    </Routes>
  </HashRouter>
)
}

/*<Route exact path="./dataprivacy" component={Privacy} /> */

export default App;
