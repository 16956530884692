
import industry from '../components/images/industry.jpeg'
import BlogArticlesCol from '../components/blogsonecol'
import SingleBlog from './testgetblog'
import WorkDb from '../components/Workdb'
import React from 'react'

export default function FrontBlog () {
    return(
    <div>
     
     <div className='body--text--frontblog'>
     
      <div className="body--text--frontblog--item1">
      <BlogArticlesCol />
      </div>
      <div className='body--text--frontblog--item2'>
        <p>What We Do</p>
      <ul className="sidemenu">
          <li>Data/Privacy</li>
          <li>Cybersecurity</li>
          <li>Telecoms</li>
          <li>IP / Confidentiality</li>
      </ul>
       {/*} <p>Where</p>
      <ul className="sidemenloc">
        <li>Paris</li>
        <li>London</li>
        <li>Berlin</li>
    </ul>*/}
      </div>
      <div className="body--text--frontblog--item3">
          <WorkDb />
      </div>
      </div>
  
    </div>
    )
  }