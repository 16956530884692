import "../style.css"
import {useState} from "react";   
import Axios from "axios";
import React from "react";

export default function ShowBlogs() {
    
    const [blogarticles, setBlog] = useState([])

    const getBlogs = () => {
    Axios.get("/readblogs").then((response) => {
        console.log(response);
        setBlog(response.data, response.key)
    })
    }
    
    return (
        <div className="sqltest">
            <h1>Artikel</h1>
            
            <button onClick={getBlogs}>alle Artikel anzeigen</button>

            {blogarticles.map((val, key) => {
                return <div className="sqlgrid">
                    <h3>Title: {val.post_title}</h3>
                    <h3>Authoren: {val.post_author}</h3>
                    <h3>Abstrakt: {val.post_abstract}</h3>
                    <h3>Volltext: {val.post_fulltext}</h3>
                    </div>
            })}
        </div>
    )}


