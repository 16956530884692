import React from "react"

export default function Footerbon() {
    return(
      <div className='footer--main'>
      <p>{" "}</p>
      <h6>©2022 Pycat Ltd.</h6>
      </div>
    )
  
  }