import React from 'react';
import '../index.css';
import {
  Link} from "react-router-dom";
import industry from "./images/industry.jpeg"
// the export default makes this function usable by the whole application


export default function Headerbon() {

  function Topnav() {
    return(
     <div>
     <div className='head--filler'>{}</div>                
       <ul className="topmenu">
        <li><Link to="/">Home</Link></li>
        <li><Link to="/blog">Blog</Link></li>
        <li><Link to="/about/1">About Us</Link></li>
        <li><Link to="/members">Members</Link></li>
        <li><Link to="/work">Recent Work</Link></li>
        {/*<li><Link to="/">Publications</Link></li>*/}
        <li><a href="https://www.linkedin.com/in/rarrate" target="_blank">Contact Us</a></li>
      </ul>
    </div>  
    )
  }

  return(
    
    <div className='header'>
    <div className="header--menu">
      <div className="topmenu">
          <Topnav/>
      </div>
      <div className="head--title">
          <h1>Bøn </h1><span><h4>.tech</h4></span>
      </div>
      </div>
     
      <div className='banner'>
        <div className='banner--menu--right'>
          <img className="banner--menu--right--pic"
          src={industry}/>
          </div>
        </div>
      
    </div>
  )

}

// this function will be exported by the Headerbon function;
