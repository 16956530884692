import './App.css';
import React from 'react';
import './index.css';
import "./style.css"
import Handlecookies from "./components/handlecookies"
import FrontBlog from "./pages/frontblogs";

function Home () {
    return(  
   <div>
    <FrontBlog />
    <Footerbon />
    <Handlecookies />
   </div>
   
    )
 }
  
  function Footerbon() {
    return(
      <div className='footer--main'>
      <p>{" "}</p>
      <h6>©2022 Pycat Ltd.</h6>
      </div>
    )
  
  }
  
  
  //need to add the prop function here with the recent work data

export default Home;
  
  