
import React from 'react';
import "../style.css"
import ShowPrivacy from '../components/showprivacy';
import Handlecookies from '../components/handlecookies';

function Privacy () {
    return(  
   <div>
    <ShowPrivacy />
    <Footerbon />
    <Handlecookies />
  </div>
   
    )
 }
  
  function Footerbon() {
    return(
      <div className='footer--main'>
      <p>{" "}</p>
      <h6>©2022 Pycat Ltd.</h6>
      </div>
    )
  
  }
  
  
  //need to add the prop function here with the recent work data

export default Privacy;