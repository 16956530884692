import React from 'react';
import CookieConsent from 'react-cookie-consent'; 
import { Link } from "react-router-dom"

export default function Handlecookies () {
  return (
    <CookieConsent
    debug={false}
    location="bottom"
    buttonText="Agree"
    containerClasses='cookie--bar--container'
    overlay={true}
    expires={30}
    style={{ background: "#73376D", fontSize: "1.2rem" }}
    buttonStyle={{ fontSize: "1.1rem"}}
    >
    This website uses cookies to enhance the user experience.
    {"  "}
    <div className='cookie--policy'><Link to="/policy" className='cookie--policy--link'>Click here to read our Cookies and Privacy Policy</Link></div>
  </CookieConsent>
  )
}