import { useParams } from "react-router-dom";
import {Route, Routes, useLocation} from "react-router-dom";
import { useEffect, useState } from "react";
import Axios from "axios";
import {LinkedinShareButton} from "react-share";
import {LinkedinIcon} from "react-share";
import BlogArticlesCol from "../components/blogsonecol"
import React from "react";


const ShowPage = () => {
    
    const pageid = 1
    const [singlepage, setPage] = useState([])
    console.log(singlepage)
    const blogurl = useLocation()
    console.log(blogurl)

  
  useEffect(() => {
    Axios.get(`https://bontech2.herokuapp.com/readpage/${pageid}`).then((response) => {
      console.log(response);
      console.log(pageid)
      setPage(response.data, response.key)

  })}, [])

  return(

   <div>
   <div className="blog--single--body">
   {/*<h1>{pageid}</h1>  */}
          
    {singlepage.map((val, key) => {
            return <article className="single--page" key={key}>
                <h3>{val.pages_title}</h3>
                <div className="linkedin--share--blog">
               </div>
                <h4>Tags: {val.pages_tag}</h4>
                <p>{val.pages_text}</p>
                </article>
        })}
        </div>
     </div>
    
  )
  }
  export default ShowPage