import { useParams } from "react-router-dom";
import {Route, Routes, useLocation} from "react-router-dom";
import { useEffect, useState } from "react";
import useFetch from "../useFetch";
import Axios from "axios";
import {LinkedinShareButton} from "react-share";
import {LinkedinIcon} from "react-share";
import BlogArticlesCol from "../components/blogsonecol"
import industry from '../components/images/industry.jpeg'
import React from "react";

const SingleWork = () => {
    
    const {workid} = useParams()
    const [workarticles, setMatters] = useState([])
    console.log(workarticles)
    const blogurl = useLocation();
    const pathurl = blogurl.pathname
    //console.log(`https://bøn.tech/#${pathurl}`)
    console.log(pathurl)
    const linkedurl = `https://xn--bn-lka.tech/#${pathurl}`
    console.log(linkedurl)


  
  useEffect(() => {
    Axios.get(`https://bontech2.herokuapp.com/matters/${workid}`).then((response) => {
      console.log(response);
      console.log(workid)
      setMatters(response.data, response.key)
  })}, [])


    return(
        
      <div>
             
        <div className="body--text">
        <div className='body--text--item1'>
          <p>What We Do</p>
          <ul className="sidemenu">
            <li>Data/Privacy</li>
            <li>Cybersecurity</li>
            <li>Telecoms</li>
            <li>IP / Confidentiality</li>
          </ul>
          
        </div>
        
        <div className="blog--single--body">
        {/*<h1>{workid}</h1>*/}   
                
        {workarticles.map((val, key) => {
                return <article className="work--single--article" key={key}>
                    <h3>{val.matter_title}</h3>
                    <div className="linkedin--share--blog">
                    <LinkedinShareButton
                    url={linkedurl}
                    summary={val.work_snipet}
                    title={val.matter_title}>
                    <LinkedinIcon size={49} round={true} />
                    </LinkedinShareButton> 
                    </div>
                    <h4>Location: {val.bon_location}</h4>
                    <h4>Summary: {val.work_snipet}</h4>
                    <h4>Tags: {val.post_tags}</h4>
                    <p>{val.work_text}</p>
                    </article>
            })}
            </div>
           <div className="blog--single--body--allblogs"> 
            
           </div> 
        </div>
        </div>
      
    )

} 

export default SingleWork