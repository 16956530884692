import '../App.css';
import React from 'react';
import ReactDOM from 'react-dom';
import '../index.css';
import reportWebVitals from '../reportWebVitals';
import "../style.css"
import intp from '../components/images/intp.png'
import CookieConsent from 'react-cookie-consent';
import Handlecookies from "../components/handlecookies"
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Privacy from './dataprivacy';
import BasicExample from '../components/example';
import Contact from "./contact"
import {LinkedinShareButton} from "react-share"
import {LinkedinIcon} from "react-share"
import MembersDb from '../components/Membersdb';
import Footerbon from '../components/footerbon';
import BlogMemCol from '../components/memblogcol';



export default function AllMembers () {
    const title = "https://www.bøn.tech";
    const summary = "hola";
    const source = "bøn";
    return(  
        <div>
                  
          <div className='member--body--grid'>
          <div className="work--body--item3">
             <MembersDb/>
          </div>

        <div className="work--body--item2">
         <BlogMemCol />
        </div>

      <div className='body--text--blog--item1'>
        <p>What We Do</p>
          <ul className="sidemenu">
            <li>Data/Privacy</li>
            <li>Cybersecurity</li>
            <li>Telecoms</li>
            <li>IP / Confidentiality</li>
          </ul>
              
      {/*<ul className="sidemenloc">
        <li>Paris</li>
        <li>London</li>
        <li>Berlin</li>
    </ul>*/}
      </div>
        
      </div>
      <div>
          <Footerbon/>
        </div>
    </div>
    )
 }
 