import { useEffect, useState } from "react";
import "../style.css" 
import { Link } from "react-router-dom";
import Axios from "axios";
import React from "react";

export default function BlogArticlesdb () {

  const [blogs, setBlogs] = useState(null);
  const [blogarticles, setBlog] = useState([])
  
  useEffect(() => {
    Axios.get("https://bontech2.herokuapp.com/readblogs").then((response) => {
      console.log(response);
      setBlog(response.data, response.key)
  })}, [])
  
  return (
                
        <div className="body--text--blog--grid">
               <div className="main--blog--card">
               {blogarticles.map((val, key) => {
                return <div className="blog--preview" key={key}>
                  <Link to={`/blogs/${val.id}`}>
                    <h3>{val.post_title}</h3>
                    <h4>Author: {val.post_author}</h4>
                    <h4>Summary: {val.post_abstract}</h4>
                    <h5>{val.post_fulltext}</h5></Link>
                    </div>
                     }
                )
              }</div>
        </div>
          )  
  }
